// Color system
@import "@coreui/coreui/scss/functions";

$enable-shadows: true;
$primary: #0097B2;
$primary-sidebar: #04889F;
$enable-gradients: true;
$font-family-sans-serif: // Cross-platform generic font family (default user interface font)
        system-ui,
        // Safari for macOS and iOS (San Francisco)
        -apple-system,
        // Windows
        "Segoe UI",
        // Android 
        Roboto,
        // older macOS and iOS
        "Helvetica Neue"
        // Linux
        "Noto Sans",
        "Liberation Sans",
        // Basic web fallback
        Arial,
        // Sans serif fallback
        sans-serif,
        // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// $danger: #ff4136;

// $theme-colors: (
//   "primary": $primary,
//   "danger": $danger
// );

$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color,
                10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue",
        helvetica,
        arial,
        sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;
$breadcrumb-active-color: #5d697e;
// $prefix: cui;
$enable-deprecation-messages: false !default;
// @each $color, $value in $theme-colors {
//         $color-rgb: to-rgb($value);
//         .text-bg-#{$color} {
//           color: color-contrast($value) if($enable-important-utilities, !important, null);
//           background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
//         }
//       }