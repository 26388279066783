// @tailwind base;
// @tailwind components;
// @tailwind utilities;


@import "variables";

// $enable-ltr: true;
// $enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";
// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";
@import "color-bg";
@import "layout";
@import "example";
// If you want to add custom CSS you can put it here.
@import "custom";
@import "animate";
@import "pagination";
@import "styletree";
@import "datepicker";


.centered {
    position: absolute;
    left: 50%;
    top: 50%;
}

@media screen, print {
  .is-text-multiple {
    width: 95mm;
    height: 140mm;
    overflow: hidden;
    line-height: 1;
    display: inline-table;
    margin: 2px;
  }
}

@-webkit-keyframes color_change {
    0% {
      background-color: white;
    }
    50% {
      background-color: #11f542;
    }
    70% {
      background-color: rgba(7, 182, 45, 0.637);
    }
    100% {
      background-color: white;
    }
  }
  @-moz-keyframes color_change {
    0% {
      background-color: white;
    }
    50% {
      background-color: #11f542;
    }
    70% {
      background-color: rgba(7, 182, 45, 0.637);;
    }
    100% {
      background-color: white;
    }
  }
  
  @keyframes color_change {
    0% {
      background-color: white;
    }
    50% {
      background-color: #11f542;
    }
    70% {
      background-color: rgba(7, 182, 45, 0.637);;
    }
    100% {
      background-color: white;
    }
  }
  
  .has-anim {
    animation-name: color_change;
    animation-duration: 5s;
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.sidebar-nav .nav-link.active {
  font-weight: 700;
}